import React from "react";
import { graphql } from "gatsby";

import { Seo } from "~/components/seo";
import { Main } from "~/layouts";
import { HeroTitle } from "~/components/hero";
import { Collections } from "~/components/collections";
import { Footer } from "~/components/footer";

const BestSellersPage = ({ data: { allShopifyProduct } }) => {
  const { nodes: products } = allShopifyProduct || {};
  return (
    <Main fullView>
      <Seo title={`Best Sellers`} />
      <HeroTitle title="Best Sellers" />
      <Collections {...{ products }} />
      <Footer />
    </Main>
  );
}

export const query = graphql`
  {
    allShopifyProduct(
      limit: 50,
      filter: {
        tags: {
          in: "best seller"
        }
      },
      sort: {
        fields: publishedAt,
        order: DESC,
      },
    ) {
      nodes {
        id
        handle
        title
        description
        descriptionHtml
        tags
        status
        vendor
        vendorSlug
        images {
          id
          altText
          originalSrc
          src
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        collections {
          handle
        }
      }
    }
  }
`;

export default BestSellersPage;
